import React from 'react';

const ModalWrapper = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-300">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="bg-gray-700 p-1 rounded-lg w-5/6 h-5/6 relative z-50">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-100 hover:text-gray-300 mr-2">
            &times;
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;