import { useState, useEffect, useCallback } from 'react';
import { serverSummary } from '../services/api';

function useServerSummary(viewId) {
  const [serverData, setServerSummary] = useState([]);
  const [error, setError] = useState(null);
  let isPaused = false;

  console.log("useServerSummary: " + viewId);
  const fetchData = useCallback(async () => {
    if (!isPaused) {
      try {
        const data = await serverSummary(viewId);
        if(!isPaused) {
          console.log("Loaded:" + viewId);
          setServerSummary(data);
          setError(null); 
        }
      } catch (err) {
        setError(err.message);
          setServerSummary([]);
        } 
    }
  }, [viewId]);
  
  useEffect(() => {
    //fetchData();
    const intervalId = setInterval(() => {
      if (!isPaused && viewId !== null) {
        console.log("isPaused: " + isPaused);
        console.log("Fetching data for view: " + viewId);
        fetchData();
      }
    }, 60*1000); // Fetch every 5 minutes if not paused

    return () => clearInterval(intervalId);
    
  }, [viewId]);

  const handlePause = useCallback(async (paused) => {
    isPaused = paused;
    console.log("Set paused: " + viewId + " to " + isPaused);
  }, [viewId]);

  return { serverData, error, refetch: fetchData, handlePause};
}

export default useServerSummary;