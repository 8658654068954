import React, { useState, useEffect } from 'react';
import { PlusIcon, PencilIcon, TrashIcon, UserPlusIcon, XIcon, CheckIcon, LogOut } from 'lucide-react';
import { useViews } from '../hooks/useViews';
import { useViewDetails } from '../hooks/useViewDetails';
import { useAuth } from '../context/AuthContext';

const ViewsModal = ({ handleExteralButtonClick }) => {
  const { user } = useAuth();
  const [newViewName, setNewViewName] = useState('');
  const [newViewDescription, setNewViewDescription] = useState('');
  const [editingViewId, setEditingViewId] = useState(null);
  const [editingViewName, setEditingViewName] = useState('');
  const [editingViewDescription, setEditingViewDescription] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('Viewer');
  const [errors, setErrors] = useState({});
  const { views, loading: viewsLoading, error: viewsError, addView, updateView, removeView, leaveFromExternalView } = useViews();
  const [selectedViewId, setSelectedViewId] = useState(null);
  const { 
    viewDetails: selectedView, 
    loading: viewDetailsLoading, 
    error: viewDetailsError, 
    inviteUserToView, 
    removeUserFromView, 
    updateUserRoleInView, 
    toggleServerInView 
  } = useViewDetails(selectedViewId);
  const [deletingViewId, setDeletingViewId] = useState(null);
  const [leavingViewId, setLeavingViewId] = useState(null);
  const [removingUserId, setRemovingUserId] = useState(null);
  const [isCreatingView, setIsCreatingView] = useState(false);
  const [isInvitingUser, setIsInvitingUser] = useState(false);

  useEffect(() => {
    if (!viewsLoading && !viewsError && views.length > 0 && !selectedViewId) {
      setSelectedViewId(views[0].id);
    }
  }, [views, viewsLoading, viewsError, selectedViewId]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateView = (name, description) => {
    const newErrors = {};
    if (!name.trim()) newErrors.viewName = "View name is required";
    if (!description.trim()) newErrors.viewDescription = "View description is required";
    return newErrors;
  };

  const handleCreateView = async () => {
    if (user.userPlan === 'trial' && views.length >= 3) {
      setErrors({ ...errors, general: "You can't create more than three views on the trial plan." });
      return;
    }
    const viewErrors = validateView(newViewName, newViewDescription);
    if (Object.keys(viewErrors).length > 0) {
      setErrors(viewErrors);
      return;
    }
    setIsCreatingView(true);
    try {
      await addView(newViewName, newViewDescription);
      setNewViewName('');
      setNewViewDescription('');
      setErrors({});
    } catch (err) {
      setErrors({ ...errors, general: err.message });
    } finally {
      setIsCreatingView(false);
    }
  };

  const handleStartEditing = (view) => {
    if (view.isExternal) return;
    setEditingViewId(view.id);
    setEditingViewName(view.name);
    setEditingViewDescription(view.description);
    setErrors({});
  };

  const handleSaveEditing = async () => {
    const viewErrors = validateView(editingViewName, editingViewDescription);
    if (Object.keys(viewErrors).length > 0) {
      setErrors(viewErrors);
      return;
    }
    try {
      await updateView(editingViewId, editingViewName, editingViewDescription);
      setEditingViewId(null);
      setErrors({});
    } catch (err) {
      setErrors({ ...errors, general: err.message });
    }
  };

  const isCreateViewDisabled = () => {
    return user.userPlan === 'trial' && views.length >= 3;
  };

  const isAddUserDisabled = () => {
    return user.userPlan === 'locked' || user.userPlan === 'trial';
  };


  const handleDeleteView = async (id) => {
    const viewToDelete = views.find(view => view.id === id);
    if (viewToDelete.isExternal) return;
    if (deletingViewId === id) {
      try {
        await removeView(id);
        if (selectedViewId === id) {
          setSelectedViewId(null);
        }
        setDeletingViewId(null);
      } catch (err) {
        setErrors({ ...errors, general: err.message });
      }
    } else {
      setDeletingViewId(id);
    }
  };

  const handleLeaveFromExternalView = async (id) => {
    const viewToLeave = views.find(view => view.id === id);
    if (!viewToLeave.isExternal) return;
    if (leavingViewId === id) {
      try {
        await leaveFromExternalView(id);
        if (selectedViewId === id) {
          setSelectedViewId(null);
        }
        setLeavingViewId(null);
      } catch (err) {
        setErrors({ ...errors, general: err.message });
      }
    } else {
      setLeavingViewId(id);
    }
  };


  const handleInviteUser = async () => {
    setIsInvitingUser(true);
    if (user.userPlan === 'trial') {
      setErrors({ ...errors, email: "You can't add users on the trial plan." });
      return;
    }
    if (user.userPlan === 'locked') {
      setErrors({ ...errors, email: "Your account is locked, please update payment details to continue." });
      return;
    }
    if (!validateEmail(newUserEmail)) {
      setErrors({ ...errors, email: "Invalid email address" });
      return;
    }
    try {
      await inviteUserToView(newUserEmail, newUserRole);
      setNewUserEmail('');
      setNewUserRole('Viewer');
      setErrors({});
    } catch (err) {
      setErrors({ ...errors, email: err.message });
    } finally {
      setIsInvitingUser(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    if (removingUserId === userId) {
      try {
        await removeUserFromView(userId);
        setRemovingUserId(null);
      } catch (err) {
        setErrors({ ...errors, general: err.message });
      }
    } else {
      setRemovingUserId(userId);
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    try {
      await updateUserRoleInView(userId, newRole);
    } catch (err) {
      setErrors({ ...errors, general: err.message });
    }
  };

  const handleToggleServer = async (serverId) => {
    try {
      await toggleServerInView(serverId);
    } catch (err) {
      setErrors({ ...errors, general: err.message });
    }
  };

  const canCreateNewView = () => {
    return user.userPlan !== 'trial' || views.length === 0;
  };

  return (
    <div className="flex text-gray-300  flex-col sm:flex-row h-[calc(100%-28px)] w-[calc(100%-4px)] mx-auto bg-gray-800 text-gray-100 rounded-lg overflow-hidden">
      {/* Left column: Views list */}
      <div className="w-full sm:w-1/3 bg-gray-800 p-4 overflow-y-auto flex flex-col">
        <h2 className="text-xl sm:text-2xl font-bold mb-4">Views</h2>
        <div className="flex flex-col mb-4">
          <input
            type="text"
            className={`bg-gray-700 border ${errors.viewName ? 'border-red-500' : 'border-gray-600'} rounded px-2 py-1 mb-2`}
            value={newViewName}
            onChange={(e) => setNewViewName(e.target.value)}
            placeholder="New View Name"
            disabled={isCreatingView}
          />
          {errors.viewName && <p className="text-red-500 text-sm mb-2">{errors.viewName}</p>}
          <input
            type="text"
            className={`bg-gray-700 border ${errors.viewDescription ? 'border-red-500' : 'border-gray-600'} rounded px-2 py-1 mb-2`}
            value={newViewDescription}
            onChange={(e) => setNewViewDescription(e.target.value)}
            placeholder="New View Description"
            disabled={isCreatingView}
          />
          {errors.viewDescription && <p className="text-red-500 text-sm mb-2">{errors.viewDescription}</p>}
          <button
            className={`bg-blue-600 px-4 py-2 rounded flex items-center justify-center ${isCreateViewDisabled() || isCreatingView ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleCreateView}
            disabled={isCreateViewDisabled() || isCreatingView}
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            {isCreatingView ? 'Creating...' : 'Create View'}
          </button>
        </div>
        {isCreateViewDisabled() && (
          <p className="text-sm text-yellow-500 mb-4">Upgrade to create more views.</p>
        )}
        <ul className="space-y-2 flex-grow overflow-y-auto">
        {viewsLoading ? (
            <p>Loading views...</p>
          ) : viewsError ? (
            <p>Error loading views: {viewsError}</p>
          ) : (
            views.map((view) => (
              <li 
                key={view.id} 
                className={`flex flex-col bg-gray-700 p-2 rounded cursor-pointer ${selectedViewId === view.id ? 'border-2 border-blue-500' : ''}`}
                onClick={() => setSelectedViewId(view.id)}
              >
              {editingViewId === view.id ? (
                <div className="flex flex-col">
                  <input
                    type="text"
                    className={`bg-gray-600 border ${errors.viewName ? 'border-red-500' : 'border-gray-500'} rounded px-2 py-1 mb-2`}
                    value={editingViewName}
                    onChange={(e) => setEditingViewName(e.target.value)}
                  />
                  {errors.viewName && <p className="text-red-500 text-sm mb-2">{errors.viewName}</p>}
                  <input
                    type="text"
                    className={`bg-gray-600 border ${errors.viewDescription ? 'border-red-500' : 'border-gray-500'} rounded px-2 py-1 mb-2`}
                    value={editingViewDescription}
                    onChange={(e) => setEditingViewDescription(e.target.value)}
                  />
                  {errors.viewDescription && <p className="text-red-500 text-sm mb-2">{errors.viewDescription}</p>}
                  <div className="flex justify-end">
                    <button className="text-green-400 mr-2" onClick={handleSaveEditing}>
                      <CheckIcon className="w-4 h-4" />
                    </button>
                    <button className="text-red-400" onClick={() => { setEditingViewId(null); setErrors({}); }}>
                      <XIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-between items-center">
                    <span className="font-bold">{view.name}</span>
                    <div>
                      {view.isExternal ? (
                        <div className="flex justify-end mt-4">
                          {leavingViewId === view.id ? (
                            <>
                              <button className="text-red-400 mr-2" onClick={(e) => { e.stopPropagation(); handleLeaveFromExternalView(view.id); }}>
                                Confirm
                              </button>
                              <button className="text-gray-400" onClick={(e) => { e.stopPropagation(); setLeavingViewId(null); }}>
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button className="text-red-400" title="Leave" onClick={(e) => { e.stopPropagation(); handleLeaveFromExternalView(view.id); }}>
                              <LogOut className="w-4 h-4" />
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-end mt-4">
                          <button className="text-blue-400 mr-2" title="Edit"  onClick={(e) => { e.stopPropagation(); handleStartEditing(view); }}>
                            <PencilIcon className="w-4 h-4" />
                          </button>
                          {deletingViewId === view.id ? (
                            <>
                              <button className="text-red-400 mr-2" onClick={(e) => { e.stopPropagation(); handleDeleteView(view.id); }}>
                                Confirm
                              </button>
                              <button className="text-gray-400" onClick={(e) => { e.stopPropagation(); setDeletingViewId(null); }}>
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button className="text-red-400" title="Delete"  onClick={(e) => { e.stopPropagation(); handleDeleteView(view.id); }}>
                              <TrashIcon className="w-4 h-4" />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="text-sm text-gray-400">{view.description}</span>
                </>
              )}
            </li>
            ))
          )}
        </ul>
      </div>

      {/* Right column: Users and Servers */}
      <div className="w-full sm:w-2/3 bg-gray-900 p-4 overflow-y-auto">
        {viewDetailsLoading && selectedViewId ? (
          <p>Loading view details...</p>
        ) : viewDetailsError ? (
          <p>Error loading view details: {viewDetailsError}</p>
        ) : views.length === 0 ? (
          <p>Please create a view to get started.</p>
        ) : selectedView ? (
          <div className="space-y-8">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">{selectedView.name}</h2>
            <p className="text-gray-400 mb-4">{selectedView.description}</p>
            {selectedView.isExternal && (
              <p className="text-yellow-500 mb-4">This is an external view and cannot be modified.</p>
            )}
            
            {/* Users Section */}
            <div>
              <h3 className="text-lg sm:text-xl font-bold mb-2">Add Users</h3>
              <div className="flex flex-col sm:flex-row mb-4">
                <input
                  type="email"
                  className={`bg-gray-700  border ${errors.email ? 'border-red-500' : 'border-gray-600'} rounded px-2 py-1 mb-2 sm:mb-0 sm:mr-2 flex-grow`}
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  placeholder="New User Email"
                  disabled={selectedView.isExternal || isAddUserDisabled() || isInvitingUser}
                />
                <select
                  className="bg-gray-700 border border-gray-600 rounded px-2 py-1 mb-2 sm:mb-0 sm:mr-2"
                  value={newUserRole}
                  onChange={(e) => setNewUserRole(e.target.value)}
                  disabled={selectedView.isExternal || isAddUserDisabled() || isInvitingUser}
                >
                  <option value="Viewer">Viewer</option>
                  <option value="Operator">Operator</option>
                </select>
                <button
                  className={`bg-green-600 px-4 py-2 rounded flex items-center justify-center sm:justify-start ${(selectedView.isExternal || isAddUserDisabled()) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleInviteUser}
                  disabled={selectedView.isExternal || isAddUserDisabled() || isInvitingUser}
                >
                  <UserPlusIcon className="w-4 h-4 mr-2" />
                  {isInvitingUser ? 'Inviting...' : 'Invite'}
                </button>
              </div>
              { user.userPlan === 'trial' && (
                  <p className="text-sm text-yellow-500 mb-4">Upgrade to add users.</p>
                )
              }
              {errors.email && <p className="text-red-500 text-sm mb-2">{errors.email}</p>}
              <ul className="space-y-2">
              {selectedView.users.map((user) => (
              <li key={user.id} className="flex flex-col sm:flex-row sm:items-center justify-between bg-gray-700 p-2 rounded">
                <span className="mb-2 sm:mb-0">{user.email}</span>
                <div className="flex items-center">
                  {!selectedView.isExternal && (
                        <>
                          <select
                            className="bg-gray-600 border border-gray-500 rounded px-2 py-1 mr-2"
                            value={user.role}
                            onChange={(e) => handleUpdateUserRole(user.id, e.target.value)}
                          >
                            <option value="Viewer">Viewer</option>
                            <option value="Operator">Operator</option>
                          </select>
                          {removingUserId === user.id ? (
                            <>
                              <button className="text-red-400 mr-2" onClick={() => handleRemoveUser(user.id)}>
                                Confirm
                              </button>
                              <button className="text-gray-400" onClick={() => setRemovingUserId(null)}>
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button className="text-red-400" onClick={() => handleRemoveUser(user.id)}>
                              <TrashIcon className="w-4 h-4" />
                            </button>
                          )}
                        </>
                      )}
                      {selectedView.isExternal && (
                        <span className="text-gray-400">{user.role}</span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Servers Section */}
            <div>
              <h3 className="text-lg sm:text-xl font-bold mb-2">Select Servers</h3>
              {user.userPlan !== 'locked' && selectedView.servers.length === 0 ? (
                <button
                  onClick={() => handleExteralButtonClick('servers')}
                  className="bg-blue-900 rounded-lg shadow-lg mt-4 w-full hover:bg-blue-800 transition-colors duration-200"
                >
                  <p className="text-blue-200 text-center p-4">
                    You have no servers configured. Click here to open "Servers" section and configure your servers.
                  </p>
                </button>
              ) : (
                <ul className="space-y-2">
                  {selectedView.servers.map((server) => (
                    <li key={server.id} className="flex items-center justify-between bg-gray-700 p-2 rounded">
                      <span className="mr-2">{server.name} (ID: {server.id})</span>
                      <input
                        type="checkbox"
                        checked={server.selected}
                        onChange={() => !selectedView.isExternal && handleToggleServer(server.id)}
                        disabled={selectedView.isExternal}
                        className="form-checkbox h-5 w-5 text-blue-600 disabled:opacity-50"
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-500 mt-8">Select a view to see details</p>
        )}
      </div>
    </div>
  );
};

export default ViewsModal;



