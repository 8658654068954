import React, { useState, useEffect } from 'react';
import { getUpcomingFeatures, createUpcomingFeatures } from '../services/api';

const UpcomingFeatures = ({ toggleUpcomingFeatures }) => {
  const [selectedFeatures, setSelectedFeatures] = useState({
    "others": ""
  });

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const features = await getUpcomingFeatures();
        setSelectedFeatures(prevState => ({
          ...features,
          "others": features.others || ""
        }));
      } catch (error) {
        console.error('Error fetching upcoming features:', error);
      }
    };

    fetchFeatures();
  }, []);

  const handleFeatureSelect = async (feature) => {
    const updatedFeatures = {
      ...selectedFeatures,
      [feature]: !selectedFeatures[feature]
    };
    setSelectedFeatures(updatedFeatures);
    
    try {
      await createUpcomingFeatures(updatedFeatures);
    } catch (error) {
      console.error('Error updating feature:', error);
      // Revert the change if the update fails
      setSelectedFeatures(selectedFeatures);
    }
  };

  const handleCustomFeatureChange = (e) => {
    setSelectedFeatures(prevSelectedFeatures => ({
      ...prevSelectedFeatures,
      "others": e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUpcomingFeatures(selectedFeatures);
      console.log('Selected features:', selectedFeatures);
      toggleUpcomingFeatures();
    } catch (error) {
      console.error('Error submitting upcoming features:', error);
    }
  };

  return (
    <div className="bg-gray-800 text-gray-200 p-5 rounded-lg shadow-lg">
      <h3 className="text-xl mb-3">Select the features you would love:</h3>
      <ul className="space-y-1 mb-5 ml-3">
        {Object.entries(selectedFeatures).map(([feature, value]) => (
          feature !== "others" && (
            <li key={feature}>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  name="feature"
                  value={feature}
                  checked={value}
                  onChange={() => handleFeatureSelect(feature)}
                  className="form-checkbox text-blue-500 h-5 w-5"
                />
                <span className="text-sm">{feature}</span>
              </label>
            </li>
          )
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="customFeature" className="block mb-1 text-sm">Anything else? Submit your own feature requests:</label>
          <textarea
            id="customFeature"
            value={selectedFeatures["others"]}
            onChange={handleCustomFeatureChange}
            className="w-full p-2 bg-gray-700 text-gray-200 rounded text-sm"
            rows="3"
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded transition duration-300 text-sm"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpcomingFeatures;
