// hooks/useViewDetails.js
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { fetchViewDetails, inviteUser, removeUser, updateUserRole, toggleServer } from '../services/api';

export const useViewDetails = (viewId) => {
  const [viewDetails, setViewDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const loadViewDetails = async () => {
      if (!viewId) return;
      try {
        const details = await fetchViewDetails(viewId);
        setViewDetails(details);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    loadViewDetails();
  }, [user.id, viewId]);

  const inviteUserToView = async (email, role) => {

    try {
      const emailExists = viewDetails.users.some(user => user.email === email);

      if (emailExists) {
        setError("This email has already been invited to this view.");
        throw new Error("This email has already been invited to this view.");
      }
      const newUser = await inviteUser(viewId, email, role);
      setViewDetails({
        ...viewDetails,
        users: [...viewDetails.users, newUser]
      });
      return newUser;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const removeUserFromView = async (userIdToRemove) => {
    try {
      await removeUser(viewId, userIdToRemove);
      setViewDetails({
        ...viewDetails,
        users: viewDetails.users.filter(user => user.id !== userIdToRemove)
      });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateUserRoleInView = async (userIdToUpdate, newRole) => {
    try {
      await updateUserRole(viewId, userIdToUpdate, newRole);
      setViewDetails({
        ...viewDetails,
        users: viewDetails.users.map(user => 
          user.id === userIdToUpdate ? { ...user, role: newRole } : user
        )
      });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const toggleServerInView = async (serverId) => {
    try {
      await toggleServer(viewId, serverId);
      setViewDetails({
        ...viewDetails,
        servers: viewDetails.servers.map(server => 
          server.id === serverId ? { ...server, selected: !server.selected } : server
        )
      });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return { 
    viewDetails, 
    loading, 
    error, 
    inviteUserToView, 
    removeUserFromView, 
    updateUserRoleInView, 
    toggleServerInView 
  };
};