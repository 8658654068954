// hooks/useViews.js
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { fetchViews, createView, renameView, deleteView } from '../services/api';

export const useViews = () => {
  const [views, setViews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const loadViews = async () => {
    try {
      const fetchedViews = await fetchViews();
      setViews(fetchedViews);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    loadViews();
  }, [user.id]);

  const addView = async (name, description) => {
    try {
      const newView = await createView(name, description);
      setViews([...views, newView]);
      return newView;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateView = async (viewId, name, description) => {
    try {
      const updatedView = await renameView(viewId, name, description);
      setViews(views.map(view => view.id === viewId ? updatedView : view));
      return updatedView;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const removeView = async (viewId) => {
    try {
      await deleteView(viewId);
      setViews(views.filter(view => view.id !== viewId));
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };


  const leaveFromExternalView = async (viewId) => {
    try {
      await deleteView(viewId);
      setViews(views.filter(view => view.id !== viewId));
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return { views, loading, error, addView, updateView, removeView , refreshViews : loadViews, leaveFromExternalView};
};