import { useState, useCallback } from 'react';
import { addServer, removeServer, updateServer, fetchServers } from '../services/api';

export const useServers = (userId) => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadServers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedServers = await fetchServers();
      setServers(fetchedServers);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const addNewServer = useCallback(async (name, description) => {
    setLoading(true);
    setError(null);
    try {
      const newServer = await addServer(name, description);
      setServers(prevServers => [...prevServers, newServer]);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const deleteServer = useCallback(async (serverId) => {
    setLoading(true);
    setError(null);
    try {
      await removeServer(serverId);
      setServers(prevServers => prevServers.filter(server => server.id !== serverId));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const editServer = useCallback(async (serverId, name, description) => {
    setLoading(true);
    setError(null);
    try {
      await updateServer(serverId, name, description);
      setServers(prevServers =>
        prevServers.map(server =>
          server.id === serverId ? { ...server, name, description } : server
        )
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  return {
    servers,
    loading,
    error,
    loadServers,
    addNewServer,
    deleteServer,
    editServer
  };
};