import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as apiLogin, fetchPermissions, apiResetPassword, signUp as apiSignUp, apiForgotPassword, fetchLatestReleaseTag } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [latestReleaseTag, setLatestReleaseTag] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeAuth = async () => {
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem('token');
      if (storedUser && storedToken) {
        const userData = JSON.parse(storedUser);
        setUser(userData);
        setToken(storedToken);
        const userPermissions = await fetchPermissions();
        setPermissions(userPermissions);
        const tag = await fetchLatestReleaseTag();
        setLatestReleaseTag(tag);
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (credentials) => {
    try {
      const { user: userData, token: authToken } = await apiLogin(credentials);
      setUser(userData);
      setToken(authToken);
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', authToken);
      localStorage.setItem('everHadPremium', userData.userPlan === 'trial' ? 'false' : 'true');
      const userPermissions = await fetchPermissions();
      setPermissions(userPermissions);
      //navigate('/dashboard');
      return true;
    } catch (error) {
      throw new Error('Invalid credentials.');
    }
  };

  const sign_up = async (credentials) => {
    try {
      const { user: userData, token: authToken } = await apiSignUp(credentials);
      setUser(userData);
      setToken(authToken);
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('token', authToken);
      const userPermissions = await fetchPermissions();
      setPermissions(userPermissions);
      const tag = await fetchLatestReleaseTag();
      setLatestReleaseTag(tag);
      return true;
    } catch (error) {
      console.error('Sign up error:', error);
      if (error.message === 'Account already exists') {
        throw new Error('Account already exists');
      }
      throw new Error('Sign up failed');
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setPermissions([]);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/login');
  };

  const resetPassword = async (newPassword) => {
    try {
      if (!user || !token) {
        throw new Error('User not authenticated');
      }
      await apiResetPassword(newPassword);
      // Optionally, you can update the stored token if it changes after password reset
      // const newToken = await apiResetPassword(user.id, newPassword, token);
      // setToken(newToken);
      // localStorage.setItem('token', newToken);
      return true;
    } catch (error) {
      console.error('Reset password error:', error);
      return false;
    }
  };

  const forgotPassword = async (email) => {
    try {
      await apiForgotPassword(email);
      return true;
    } catch (error) {
      console.error('Forgot password error:', error);
      if (error.message === 'User account not found') {
        throw new Error('User account not found');
      }
      throw new Error('Password reset failed');
    }
  };

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, token, login, sign_up, logout, hasPermission, resetPassword, forgotPassword, latestReleaseTag }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);