//const BASE_URL = `${window.location.origin.split(':')[0]}:${window.location.origin.split(':')[1]}:8000`;
const BASE_URL = 'https://statusapi.ziphio.com';

async function apiRequest(endpoint, method = 'GET', body = null, queryParams = {}) {
  const token = localStorage.getItem('token');
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    //credentials: 'include',
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const url = new URL(`${BASE_URL}${endpoint}`);
  Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

  const response = await fetch(url, options);
  
  if (!response.ok) {
    if (response.status === 401) {
      // Token expired or invalid, logout the user
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function getCurrentOffer() {
  // Mock data for current offers
  return await apiRequest('/current_offer', 'GET');
}


export async function login(credentials) {
  // This should be implemented in your mock API
  const response = await fetch(`${BASE_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.json();
}

export async function signUp(credentials) {
  const response = await fetch(`${BASE_URL}/create_account`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Account already exists');
    }
    throw new Error('Sign up failed');
  }

  return await response.json();
}

export async function apiResetPassword(newPassword) {
  return apiRequest('/reset_password', 'POST', { new_password: newPassword });
}

export async function apiForgotPassword(email) {
  const response = await fetch(`${BASE_URL}/forgot_passwort`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('User account not found');
    }
    throw new Error('Password reset failed');
  }

  return await response.json();
}

export async function fetchPermissions() {
  // This should be implemented in your mock API
  return apiRequest(`/permissions`);
}

// API functions
export async function searchBooks() {
  return apiRequest('/search_books');
}

export async function serverSummary(viewId) {
  return apiRequest(`/server_summary/${viewId}`);
}

export async function fetchViews() {
  return apiRequest(`/views`);
}

export async function createView(name, description) {
  return apiRequest('/views', 'POST', { name, description });
}

export async function renameView(viewId, name, description) {
  return apiRequest(`/views/${viewId}`, 'PUT', { name, description });
}

export async function deleteView(viewId) {
  return apiRequest(`/views/${viewId}`, 'DELETE', {});
}

export async function fetchViewDetails(viewId) {
  return apiRequest(`/views/${viewId}`);
}

export async function inviteUser(viewId, email, role) {
  return apiRequest(`/views/${viewId}/users`, 'POST', {email, role });
}

export async function removeUser(viewId, userIdToRemove) {
  return apiRequest(`/views/${viewId}/users/${userIdToRemove}`, 'DELETE', {});
}

export async function updateUserRole(viewId, userIdToUpdate, newRole) {
  return apiRequest(`/views/${viewId}/users/${userIdToUpdate}`, 'PUT', {new_role: newRole });
}

export async function toggleServer(viewId, serverId) {
  return apiRequest(`/views/${viewId}/servers/${serverId}`, 'PUT', {});
}

export async function addServer(name, description) {
  return apiRequest('/servers', 'POST', {name, description });
}

export async function removeServer(serverId) {
  return apiRequest(`/servers/${serverId}`, 'DELETE', {});
}

export async function updateServer(serverId, name, description) {
  return apiRequest(`/servers/${serverId}`, 'PUT', {name, description });
}

export async function fetchServers() {
  return apiRequest(`/servers`);
}

export async function setBenchmark(viewId, serverId, key, value) {
  return apiRequest(`/views/${viewId}/servers/${serverId}/benchmarks`, 'PUT', {key, value });
}

export async function changeGridOrder(viewId, gridOrder) {
  return apiRequest(`/views/${viewId}/grid_order`, 'PUT', { grid_order: gridOrder });
}

export const fetchChartData = async (serverId, graph_type, duration, currentDate) => {
  try {
    const standard_format = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await apiRequest(`/views/${serverId}/chart_data?graph_type=${graph_type}&duration=${duration}&current_date=${standard_format}&timezone=${encodeURIComponent(timezone)}`);
    return response;
  } catch (error) {
    console.error('Error fetching chart data:', error);
    throw error;
  }
};

export async function fetchLatestReleaseTag() {
  try {
    const response = await fetch('https://api.github.com/repos/Tetraa-Group/Server-Status-Client/releases');
    if (!response.ok) {
      return null;
    }
    const releases = await response.json();
    if (releases.length > 0) {
      return releases[0].tag_name;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching latest release tag:', error);
    return null;
  }
}

export async function getUpcomingFeatures() {
  return apiRequest('/upcoming_features', 'GET');
}

export async function createUpcomingFeatures(upcomingFeatures) {
  return apiRequest('/upcoming_features', 'POST', upcomingFeatures);
}
