import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViews } from '../hooks/useViews';
import { useAuth } from '../context/AuthContext';
import { ChevronLeft, ChevronRight, ViewIcon, CreditCard, LogOut, ChevronDown, RefreshCw, ServerIcon, LockIcon } from 'lucide-react';
import FullscreenWrapper from '../components/FullscreenWrapper';
import ModalWrapper from '../components/ModalWrapper';
import ViewsModal from "../components/ViewsModal"
import Billing from '../components/Billing';
import Servers from '../components/Servers';
import ProfileDropdown from '../components/ProfileDropdown';
import useServerSummary from '../hooks/useServerSummary';
import ServerOverviewGrid from '../components/ServerOverviewGrid';
import UpcomingFeatures from '../components/UpcomingFeatures';

const Dashboard = () => {
  const [activeViewId, setActiveViewId] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpcomingFeaturesExpanded, setIsUpcomingFeaturesExpanded] = useState(() => {
    const storedState = localStorage.getItem('isUpcomingFeaturesExpanded');
    return storedState !== null ? JSON.parse(storedState) : true;
  });
  
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { views, loading, error, refreshViews } = useViews();
  const tabsRef = useRef(null);
  const dropdownRef = useRef(null);

  const isPlanLocked = user.userPlan === "locked";
  const { serverData, error: serverError, refetch, handlePause } = useServerSummary(activeViewId);
  

  const setActiveViewIdMemoized = useCallback((id) => {
    if (id) {
      setIsLoading(true);
      setActiveViewId(id);
    }
  }, []);

  useEffect(() => {
    if (!loading && views.length > 0 && !activeViewId) {
      
      const storedViewId = parseInt(localStorage.getItem(user.id + 'activeViewId'));
      
      if (storedViewId && views.some(view => view.id === storedViewId)) {
        setActiveViewIdMemoized(storedViewId);
        
      } else {
        setActiveViewIdMemoized(views[0].id);
        
      }
    }
  }, [views, loading, setActiveViewIdMemoized, activeViewId]);


  
  useEffect(() => {
    if (activeViewId) {
      
      localStorage.setItem(user.id + 'activeViewId', activeViewId);
      refetch().then(() => setIsLoading(false));
    }
  }, [activeViewId]);

  const checkScroll = useCallback(() => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [views, checkScroll]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    setIsLoading(true);
    try {
      await Promise.all([refreshViews(), refetch()]);
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  }, [refreshViews, refetch]);

  const handleButtonClick = useCallback((action) => {
    setIsModalOpen(true);
    switch (action) {
      case 'logout':
        logout();
        break;

      case 'billing':
        /*
        setModalContent(
          <Billing 
            onClose={() => setIsModalOpen(false)} 
          />
        ); */
        break;

      case 'views':
        setModalContent(
          <ViewsModal 
            onClose={async () => {
              setIsModalOpen(false);
              await handleRefresh();
            }}
            open={isModalOpen}
            handleExteralButtonClick={handleExteralButtonClick}
          />
        );
        break;
      case 'servers':
        setModalContent(
          <Servers 
          onClose={() => setIsModalOpen(false)} 
          />
        );
        break;
      default:
        setModalContent(null);
    }
  }, [logout, handleRefresh]);
  
  const handleExteralButtonClick = useCallback((action) => {
    
    switch (action) {
      case 'servers':
        setTimeout(() => {
          handleButtonClick('servers');
        }, 100);
        break;
      case 'views':
        setTimeout(() => {
          handleButtonClick('views');
        }, 100);
        break;
    }
  }, [handleButtonClick]);

  const scroll = useCallback((scrollOffset) => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  }, []);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);

  const activeView = useMemo(() => views.find(view => view.id === activeViewId), [views, activeViewId]);

  const toggleUpcomingFeatures = useCallback(() => {
    setIsUpcomingFeaturesExpanded(prev => {
      const newState = !prev;
      localStorage.setItem('isUpcomingFeaturesExpanded', JSON.stringify(newState));
      return newState;
    });
  }, []);

  if (loading) {
    return (
      <FullscreenWrapper>
        <div className="min-h-screen bg-gray-900 flex items-center justify-center text-gray-300">
          <p className="text-xl">Loading views...</p>
        </div>
      </FullscreenWrapper>
    );
  }

  if (error) {
    return (
      <FullscreenWrapper>
        <div className="min-h-screen bg-gray-600 flex items-center justify-center">
          <p className="text-xl text-red-500">Error loading views: {error.message}</p>
        </div>
      </FullscreenWrapper>
    );
  }
  console.log("Dashboard rendered");
  return (
    <FullscreenWrapper>
      <div className="min-h-screen bg-gray-900 text-gray-300">
        <header className="flex flex-wrap items-center justify-between pt-4 gap-2 ml-4 mr-4">
          <div className="flex items-center flex-grow max-w-full sm:max-w-[calc(100%-200px)]">
            {/* Mobile and Small Tablet Dropdown */}
            <div className="relative lg:hidden w-full max-w-[200px]" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="w-full px-3 py-1.5 text-sm bg-gray-800 rounded-md flex justify-between items-center"
              >
                <span className="truncate mr-2">{activeView ? activeView.name : 'Select View'}</span>
                <ChevronDown size={16} />
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-gray-800 rounded-md shadow-lg">
                  {views.map((view) => (
                    <button
                      key={view.id}
                      onClick={() => {
                        if (!view.isLocked) {
                          setActiveViewIdMemoized(view.id);
                          setIsDropdownOpen(false);
                        }
                      }}
                      className={`w-full px-3 py-1.5 text-left text-sm hover:bg-gray-700 truncate flex items-center justify-between ${
                        view.isLocked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
                      }`}
                      disabled={view.isLocked}
                    >
                      {view.name}
                      {view.isLocked && <LockIcon size={14} className="ml-2" />}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Tablet and Desktop Tabs */}
            <div className="hidden lg:flex items-center space-x-2 overflow-x-auto max-w-full">
              {showLeftArrow && (
                <button
                  onClick={() => scroll(-100)}
                  className="p-1 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition-colors duration-200 flex-shrink-0"
                >
                  <ChevronLeft size={20} />
                </button>
              )}
              <div
                ref={tabsRef}
                className="flex space-x-2 overflow-x-auto scrollbar-hide"
                style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                onScroll={checkScroll}
              >
                {views.map((view) => (
                  <button
                    key={view.id}
                    onClick={() => !view.isLocked && setActiveViewIdMemoized(view.id)}
                    className={`px-3 py-1.5 rounded-md text-sm font-medium whitespace-nowrap transition-colors duration-200 flex-shrink-0 flex items-center ${
                      activeViewId === view.id
                        ? 'bg-blue-600 text-white'
                        : view.isLocked
                        ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                        : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    }`}
                    title={view.isLocked ? 'This view is locked' : `Open ${view.name}`}
                    disabled={view.isLocked}
                  >
                    {view.name}
                    {view.isLocked && <LockIcon size={14} className="ml-2" />}
                  </button>
                ))}
              </div>
              {showRightArrow && (
                <button
                  onClick={() => scroll(100)}
                  className="p-1 bg-gray-700 rounded-full text-gray-300 hover:bg-gray-600 transition-colors duration-200 flex-shrink-0"
                  title="Scroll Right"
                >
                  <ChevronRight size={20} />
                </button>
              )}
              <button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={`p-1 bg-gray-700 rounded-full text-gray-300 hover:bg-gray-600 transition-colors duration-200 flex-shrink-0 ${
                  isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="Refresh Views"
              >
                <RefreshCw size={20} className={isRefreshing ? 'animate-spin' : ''} />
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-1">
            <button
              onClick={() => !isPlanLocked && handleButtonClick('views')}
              className={`hidden lg:flex py-1.5 px-3 bg-gray-800 rounded-md transition-colors duration-200 flex items-center ${
                isPlanLocked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
              }`}
              aria-label="Views"
              title={isPlanLocked ? 'Views (Locked)' : 'Views'}
              disabled={isPlanLocked}
            >
              <ViewIcon size={18} className="mr-1" /> <span className="hidden xl:inline">Views</span>
            </button>
            <button
              onClick={() => !isPlanLocked && handleButtonClick('servers')}
              className={`hidden lg:flex py-1.5 px-3 bg-gray-800 rounded-md transition-colors duration-200 flex items-center ${
                isPlanLocked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
              }`}
              aria-label="Servers"
              title={isPlanLocked ? 'Servers (Locked)' : 'Servers'}
              disabled={isPlanLocked}
            >
              <ServerIcon size={18} className="mr-1" /> <span className="hidden xl:inline">Servers</span>
            </button>
            <button
              onClick={() => handleButtonClick('billing')}
              className="py-1.5 px-3 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center"
              aria-label="Billing"
              title="Billing"
            >
              <CreditCard size={18} className="mr-1" /> <span className="hidden xl:inline">Billing</span>
            </button>
            <ProfileDropdown
              onLogout={() => handleButtonClick('logout')}
            />

            <ModalWrapper 
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                if (modalContent && modalContent.props && modalContent.props.onClose) {
                  modalContent.props.onClose();
                }
              }}
            >
              {modalContent}
            </ModalWrapper>
          </div>
        </header>
        <main className="">
          <div className="bg-gray-900 bg-opacity-100 min-h-screen">
            {isLoading ? (
              <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : activeViewId ? (
              serverError ? (
                <div className="flex justify-center items-center h-screen text-red-500">Error: {serverError}</div>
              ) : (
                <ServerOverviewGrid initialServers={serverData} viewId={activeViewId} handlePause={handlePause} handleExteralButtonClick={handleExteralButtonClick} />
              )
            ) : (
              <button
                onClick={() => {
                  if (views.length > 0 && views.every(view => view.isLocked)) {
                    if (views.every(view => view.isExternal)) {
                      // Contact admin action (you might want to show a modal with admin contact info)
                      handleButtonClick('billing');
                    } else {
                      // Update payment details action
                      handleButtonClick('billing');
                    }
                  } else if (user.userPlan === "locked") {
                    // Update payment details action
                    handleButtonClick('billing');
                  } else {
                    // Open Views modal
                    handleButtonClick('views');
                  }
                }}
                className="bg-blue-900 text-blue-200 text-center p-4 mt-4 w-full hover:bg-blue-800 transition-colors duration-200"
              >
                {views.length > 0 && views.every(view => view.isLocked)
                  ? views.every(view => view.isExternal) 
                    ? "All views are currently locked. Click here to contact your admin or update payment details."
                    : "All views are currently locked. Click here to update payment details."
                  : user.userPlan === "locked"
                    ? "All views are currently locked. Click here to update payment details." 
                    : "Click here to go to Views and create a view to get started."}
              </button>
            )}
          </div>
        </main>
        <div className="fixed bottom-0 left-0 w-full lg:w-1/3 p-4">
          <div className="bg-gray-800 rounded-lg shadow-lg">
            <button
              onClick={toggleUpcomingFeatures}
              className="w-full px-4 py-2 text-left text-gray-200 bg-gray-700 rounded-t-lg flex justify-between items-center"
            >
              <span>Upcoming Features</span>
              <ChevronDown size={16} className={`transform transition-transform ${isUpcomingFeaturesExpanded ? 'rotate-180' : ''}`} />
            </button>
            {isUpcomingFeaturesExpanded && (
              <div className="p-4">
                <UpcomingFeatures toggleUpcomingFeatures={toggleUpcomingFeatures}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </FullscreenWrapper>
  );
};

export default React.memo(Dashboard);