import React, { useState, useEffect, useRef } from 'react';
import ServerOverview from './ServerOverview';
import '../styles/ServerOverviewGrid.css';
import { changeGridOrder } from '../services/api';

const ServerOverviewGrid = ({ initialServers, viewId, handlePause, handleExteralButtonClick}) => {
  const [servers, setServers] = useState(initialServers);
  const [draggingId, setDraggingId] = useState(null);
  const [droppedId, setDroppedId] = useState(null);
  const gridRef = useRef(null);
  const [columns, setColumns] = useState(3);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      if (width < 900) setColumns(1);
      else if (width < 1350) setColumns(2);
      else if (width < 1800) setColumns(3);
      else if (width < 2250) setColumns(4);
      else if (width < 2700) setColumns(5);
      else if (width < 3150) setColumns(6);
      else if (width < 3600) setColumns(7);
      else setColumns(8);
    };

    updateColumns();
    window.addEventListener('resize', updateColumns);
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  useEffect(() => {
    setServers(initialServers);
  }, [initialServers]);

  const onDragStart = (e, id) => {
    const card = e.target.closest('.server-overview-wrapper');
    const cardRect = card.getBoundingClientRect();
    const dragThreshold = cardRect.height * 0.1; // 10% of the card height

    if (e.clientY - cardRect.top <= dragThreshold) {
      setDraggingId(id);
      e.dataTransfer.setData('text/plain', id);
      e.target.classList.add('is-dragging');
    } else {
      e.preventDefault();
    }
  };

  const onDragEnd = (e) => {
    setDraggingId(null);
    e.target.classList.remove('is-dragging');
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = async (e, targetId) => {
    e.preventDefault();
    const sourceId = e.dataTransfer.getData('text');
    const newServers = [...servers];
    const sourceIndex = newServers.findIndex(s => s.id === parseInt(sourceId));
    const targetIndex = newServers.findIndex(s => s.id === targetId);
    if (sourceIndex !== targetIndex) {
      const [removed] = newServers.splice(sourceIndex, 1);
      newServers.splice(targetIndex, 0, removed);
      setServers(newServers);
      setDroppedId(targetId);
      setTimeout(() => setDroppedId(null), 300);

      // Update grid order on the server
      const gridOrder = newServers.map(server => server.id).join(',');
      try {
        await changeGridOrder(viewId, gridOrder);
      } catch (error) {
        console.error('Failed to update grid order:', error);
        // Optionally, you can revert the change or show an error message to the user
      }
    }
  };

  if (servers.length === 0) {
    return (
      <button
        onClick={() => handleExteralButtonClick("views")}
        className="bg-blue-900 rounded-lg shadow-lg mt-4 w-full hover:bg-blue-800 transition-colors duration-200"
      >
        <p className="text-blue-200 text-center p-4">
          No servers selected. Click here to go to Views and select a server.
        </p>
      </button>
    );
  }
  
  return (
    <div 
      ref={gridRef}
      className="server-grid bg-opacity-0"
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: '16px',
        padding: '16px',
        borderRadius: '8px'
      }}
    >
      {servers.map((server) => (
        <div
          key={server.id}
          draggable
          className={`server-overview-wrapper ${draggingId === server.id ? 'is-dragging' : ''} ${droppedId === server.id ? 'dropped' : ''}`}
          onDragStart={(e) => onDragStart(e, server.id)}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, server.id)}
        >
          <ServerOverview data={server} viewId={viewId} handlePause={handlePause} handleExteralButtonClick={handleExteralButtonClick}/>
        </div>
      ))}
    </div>
  );
};

export default ServerOverviewGrid;