import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon, CheckCircle } from 'lucide-react';
import { getCurrentOffer } from '../services/api';

const LandingPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [credentials, setCredentials] = useState({ name: '', email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState(null);
  const { login, sign_up, forgotPassword } = useAuth();
  const navigate = useNavigate();
  
  const videoRef = useRef(null);
  const [videoError, setVideoError] = useState(null);
  const [videoStatus, setVideoStatus] = useState('loading');
  const [currentOffer, setCurrentOffer] = useState(null);
  const [hasEverHadPremium, setHasEverHadPremium] = useState(false);
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    const fetchCurrentOffer = async () => {
      try {
        const offer = await getCurrentOffer();
        setCurrentOffer(offer);
      } catch (error) {
        console.error('Error fetching current offer:', error);
      }
    };

    fetchCurrentOffer();

    // Check if user has ever logged in
    const everHadPremium = localStorage.getItem('everHadPremium');
    setHasEverHadPremium(everHadPremium === 'true');
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleError = (e) => {
        setVideoError(e.target.error ? `Error: ${e.target.error.code}; details: ${e.target.error.message}` : 'Unknown error occurred');
        setVideoStatus('error');
      };

      const handleLoadedData = () => {
        setVideoStatus('loaded');
      };

      video.addEventListener('error', handleError);
      video.addEventListener('loadeddata', handleLoadedData);

      fetch(video.src)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        })
        .catch(e => {
          setVideoError(`Failed to load video: ${e.message}`);
          setVideoStatus('error');
        });
      
      return () => {
        video.removeEventListener('error', handleError);
        video.removeEventListener('loadeddata', handleLoadedData);
      };
    }
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!isLogin && !validateEmail(credentials.email)) {
      setNotification({ type: 'error', message: 'Please enter a valid email address.' });
      return;
    }

    setIsSubmitting(true);
    setNotification(null);
    try {
      if (isForgotPassword) {
        await forgotPassword(credentials.email);
        setNotification({ type: 'success', message: 'Please check your email for the new password.' });
        setIsForgotPassword(false);
      } else if (isLogin) {
        await login({ email: credentials.email, password: credentials.password });
        navigate('/dashboard');
      } else {
        await sign_up(credentials);
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.message === 'Invalid credentials.') {
        setNotification({ type: 'error', message: 'Invalid email or password.' });
      } else if (error.message === 'User account not found') {
        setNotification({ type: 'error', message: 'No account found with this email address.' });
      } else if (error.message === 'Account already exists') {
        setNotification({ type: 'error', message: 'An account with this email already exists.' });
      } else {
        setNotification({ type: 'error', message: 'An error occurred. Please try again.' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-900 text-gray-200">
      {/* Left side - Login/Register Form */}
      <div className="w-full md:w-1/3 relative flex flex-col min-h-screen md:min-h-0">
        {/* Background Video */}
        <div className="absolute inset-0 w-full h-full">
          {videoStatus === 'error' ? (
            <div className="w-full h-full flex items-center justify-center bg-gray-800 text-gray-400">
              {videoError}
            </div>
          ) : (
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={`${process.env.PUBLIC_URL}/assets/landing_demo.m4v`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        {videoStatus === 'loading' && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-30">
            <p>Loading video...</p>
          </div>
        )}

        {/* Form Overlay */}
        <div className="relative flex-grow flex items-center justify-center p-4 bg-gray-800 bg-opacity-10 backdrop-blur-sm overflow-y-auto">
          <form onSubmit={handleSubmit} className="bg-gray-800 p-8 rounded-xl shadow-lg w-full max-w-md transition-all duration-300 ease-in-out">
            <h2 className="text-3xl font-bold mb-6 text-blue-400">
              {isForgotPassword ? 'Forgot Password' : isLogin ? 'Welcome' : 'Create Account'}
            </h2>
            {!isLogin && !isForgotPassword && (
              <input
                type="text"
                name="name"
                id="name"
                value={credentials.name}
                onChange={handleChange}
                placeholder="Full Name"
                required
                className="w-full p-3 mb-4 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              />
            )}
            <input
              type="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
              className="w-full p-3 mb-4 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
            />
            {!isForgotPassword && (
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                  className="w-full p-3 mb-6 border border-gray-600 rounded-lg pr-10 bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-200 transition-colors duration-300"
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-5 w-5 mb-5" />
                  ) : (
                    <EyeIcon className="h-5 w-5 mb-5" />
                  )}
                </button>
              </div>
            )}
            <button 
              type="submit" 
              className={`w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'active:bg-blue-800'}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processing...' : (isForgotPassword ? 'Reset Password' : isLogin ? 'Sign In' : 'Create Account')}
            </button>
            {!isForgotPassword && (
              <p className="mt-4 text-center text-sm text-gray-400">
                {isLogin ? "Don't have an account? " : "Already have an account? "}
                <button type="button" onClick={() => setIsLogin(!isLogin)} className="text-blue-400 hover:underline font-semibold transition-colors duration-300">
                  {isLogin ? 'Sign Up' : 'Sign In'}
                </button>
              </p>
            )}
            {isLogin && !isForgotPassword && (
              <p className="mt-2 text-center text-sm">
                <button type="button" onClick={() => setIsForgotPassword(true)} className="text-blue-400 hover:underline font-semibold transition-colors duration-300">
                  Forgot Password?
                </button>
              </p>
            )}
            {isForgotPassword && (
              <p className="mt-4 text-center text-sm">
                <button type="button" onClick={() => setIsForgotPassword(false)} className="text-blue-400 hover:underline font-semibold transition-colors duration-300">
                  Back to Sign In
                </button>
              </p>
            )}
            {notification && (
              <div className={`mt-4 p-3 text-center text-sm rounded-lg ${notification.type === 'error' ? 'bg-red-900 text-red-200' : 'bg-green-900 text-green-200'} transition-all duration-300`}>
                {notification.message}
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Right side - Product Information */}
      <div className="w-full md:w-2/3 bg-gray-800 p-8 md:p-12 flex flex-col justify-center overflow-y-auto">
        <h1 className="text-4xl font-bold mb-6 text-gray-200 text-center">Welcome to Track Server Health</h1>
        <p className="text-xl mb-8 text-center text-gray-400">Stay ahead of server issues with proactive monitoring and real-time alerts.</p>
        
        {currentOffer && !hasEverHadPremium && (
          <div className="bg-gray-700 p-6 rounded-xl shadow-sm mb-8 text-center transition-all duration-300">
            <h2 className="text-2xl font-bold mb-2 text-blue-400">Special Offer</h2>
            <p className="text-lg text-gray-300 mb-2">Sign up with a credit card to get:</p>
            <p className="text-3xl font-bold text-blue-400 mb-2">${currentOffer.credits} in Free Credits (~6 months free)</p>
            <p className="text-sm text-gray-400">
              Offer valid until {new Date(currentOffer.end_date).toLocaleDateString()}
              <br />
              Limited to the next {currentOffer.remaining_offers_count} sign-ups
            </p>
          </div>
        )}
        
        <div className="grid md:grid-cols-[2fr,3fr] gap-8">
          <div className="bg-gray-700 p-6 rounded-xl shadow-sm">
            <h2 className="text-2xl font-bold mb-2 text-gray-200">Free Plan</h2>
            <ul className="space-y-3 text-gray-300">
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Monitor 10 Servers
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Create 3 Dashboards
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Set Performance Benchmarks
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-yellow-500" />
                Real-time Alerts (Coming Soon)
              </li>
            </ul>
            <button 
              onClick={() => {
                setIsLogin(false);
                setIsForgotPassword(false);
                setTimeout(() => {
                  document.getElementById('name').focus();
                }, 0);
              }} 
              className="mt-4 w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300"
            >
              Sign Up Now
            </button>
          </div>
          
          <div className="bg-gray-700 p-6 rounded-xl shadow-sm">
            <h2 className="text-2xl font-bold mb-4 text-gray-200">Pro Plan</h2>
            <ul className="space-y-3 text-gray-300">
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                $4.99/month for up to 20 servers ($0.20/month per extra server)
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                $2.99/month per each extra user added
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Unlimited Dashboards (Views)
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Set Performance Benchmarks
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-yellow-500" />
                Real-time Alerts (Coming Soon)
              </li>
              <li className="flex items-center">
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Priority Support
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-10 text-center">
          <p className="text-lg text-gray-400 mb-4">Prevent downtime and optimize performance with proactive server monitoring.</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;