import React, { useState, useRef, useEffect } from 'react';
import { LogOut, User, Key, ChevronDown, Eye, EyeOff } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const ProfileDropdown = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dropdownRef = useRef(null);
  const { user, resetPassword } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsResettingPassword(false);
        setNewPassword('');
        setConfirmPassword('');
        setError('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }
    try {
      await resetPassword(newPassword);
      setIsResettingPassword(false);
      setNewPassword('');
      setConfirmPassword('');
      setError('');
      // Optionally, show a success message here
    } catch (error) {
      setError("Failed to reset password. Please try again.");
    }
  };
  
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="py-1.5 px-3 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center"
        aria-label="Profile"
        title="Profile"
      >
        <User size={18} className="mr-1" />
        <span className="hidden xl:inline mr-1">Profile</span>
        <ChevronDown size={16} />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-gray-800 rounded-md shadow-lg z-10 border border-gray-600">
          <div className="px-4 py-3 border-b border-gray-700">
            <p className="text-sm font-medium">{user.name || 'User'}</p>
            <p className="text-xs text-gray-400 truncate">{user.email || 'email@example.com'}</p>
            <p className="text-xs text-gray-400 mt-1">Plan: {user.userPlan ? user.userPlan.split('_').pop().charAt(0).toUpperCase() + user.userPlan.split('_').pop().slice(1).toLowerCase() : 'N/A'}</p>
          </div>
          {!isResettingPassword ? (
            <>
              <button
                onClick={() => setIsResettingPassword(true)}
                className="w-full text-left px-4 py-2 text-sm hover:bg-gray-700 flex items-center"
              >
                <Key size={16} className="mr-2" />
                Reset Password
              </button>
              <button
                onClick={onLogout}
                className="w-full text-left px-4 py-2 text-sm hover:bg-gray-700 flex items-center"
              >
                <LogOut size={16} className="mr-2" />
                Logout
              </button>
            </>
          ) : (
            <form onSubmit={handleResetPassword} className="p-4">
              <div className="mb-4">
                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-300 mb-1">
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 rounded-md text-white pr-10 border border-gray-600"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300 mb-1">
                  Confirm New Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-700 rounded-md text-white border border-gray-600"
                  required
                />
              </div>
              {error && <p className="text-red-500 text-xs mb-4">{error}</p>}
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setIsResettingPassword(false)}
                  className="bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-500 transition-colors duration-200 border border-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-200 border border-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;